import React from 'react'
import './HomePage.css'
import GradientBackground from '../GradientBackground/GradientBackground.js'
import ImageScroller from '../ImageScroller/ImageScroller.js'
import '../OverflowHiddenPage/OverflowHiddenPage.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'


const HomePage = () => {
  return (
    <div className="overflow-hidden">
<div className="homepage-container">
    <GradientBackground />
        <div className='scroll-please'>
            <h1 className="homepage-title">Hi, I'm <span className="homepage-name">Isaac</span>!</h1>

            <p className='contact-info'>
            

            <a className='contact-resume' href='resume.pdf' target="_blank" rel="noreferrer"> <FontAwesomeIcon icon={faFilePdf}/> &nbsp; Resume</a> 

            </p>

            <div className="homepage-about">
            <p className="intro-text">
                Waterloo CS '29 🎉
            </p>
            <p className="story-text">
                Throughout my childhood, I've always been interested in mathematics and programming. My favourite subject throughout my eight long years of elementary school was always math, which switched to computer science throughout the duration of high school. I guess this happened because at the ripe old age of 12, my dad, wielding his fantasical Chinese math and engineering degrees, began teaching me about computers, and how to code in Python.
                <br/><br/>I've loved AI ever since I watched the first Transformers movie, even though I didn't even know what AI was at the time. My other interests include playing badminton, writing (a lot), and playing video games (I spent an unhealthy amount of time playing during COVID).
            </p>

            <div className='homepage-images'>
            <ImageScroller />
            </div>



            </div>
        </div>
    </div>
    </div>
  
  )
}

export default HomePage